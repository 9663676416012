<template>
  <svg class="BarChart" :viewBox="`0 0 ${width + marginLeft / 2} ${height + marginTop}`">
    <g
      :transform="`translate(${marginLeft / 2}, ${marginTop / 2})`"
    >
      <g
        class="x-axis"
        fill="none"
        :transform="`translate(0, ${height})`"
        style="color: #888;"
      >
        <g
          class="tick"
          opacity="1"
          font-size="11"
          font-family="ubuntu"
          color="#766e68"
          text-anchor="middle"
          v-for="(bar, index) in bars"
          :key="index"
          :transform="`translate(${bar.x + bar.width / 2}, 0)`"
        >
          <text
            fill="#766E68"
            y="9"
            dy="0.71em"
          >
            {{ bar.xLabel }}
          </text>
        </g>
      </g>
      <g
        class="y-axis"
        fill="none"
        :transform="`translate(0, 0)`"
        style="color: #888;"
      >
        <g
          class="tick"
          opacity="1"
          font-size="11"
          font-family="ubuntu"
          color="#766e68"
          text-anchor="end"
          v-for="(tick, index) in yTicks"
          :key="index"
          :transform="`translate(12, ${y(tick)})`"
        >
          <line stroke="#DEDBD6" :x2="`${width}`" />
          <text
            fill="#766E68"
            x="-4.5"
            dy="0.32em"
          >
            {{ tick }}
          </text>
        </g>
      </g>
      <g class="bars" fill="none">
        <rect
          v-for="(bar, index) in bars"
          :key="index"
          v-tooltip="{
            content: `
                <div>
                  <div>
                    <span>${$t('account.credits.chart.detail.spend', 'Spend')}:</span>
                    <span>${bar.debit}</span>
                  </div>
                  <div>
                    <span>${$t('account.credits.chart.detail.added', 'Added')}:</span>
                    <span>${bar.credit}</span>
                  </div>
                  <div>
                    <span>${$t('account.credits.chart.detail.remaining', 'Remaining')}:</span>
                    <span>${bar.balance}</span>
                  </div>
                <div>`,
            offset: 2,
            trigger: 'click hover focus',
            placement: 'top',
            html: true
          }"
          :fill="fill"
          :height="bar.height"
          :width="bar.width"
          :x="bar.x"
          :y="bar.y"
          rx="4"
          ry="4"
          class="bar"
        />
      </g>
    </g>
  </svg>
</template>

<script setup>
  import { computed, ref, watchEffect } from 'vue';
  import { scaleLinear, scaleBand } from 'd3-scale';
  import { colorCurrency, formatCurrency } from '@/utilities/filters';

  const props = defineProps({
    height: { type: Number, default: 200 },
    width: { type: Number, default: 420 },
    dataSet: { type: Array, default: () => [] },
    marginLeft: { type: Number, default: 40 },
    marginTop: { type: Number, default: 40 },
    marginBottom: { type: Number, default: 40 },
    marginRight: { type: Number, default: 40 },
    tickCount: { type: Number, default: 5 },
    barPadding: { type: Number, default: 0.3 },
    currency: { type: String, default: 'USD' },
    fill: { type: String, default: '#73BD07' }
  });

  const data = computed(() => props.dataSet?.map(d => ({
    x: new Date(d.date).toLocaleString('default', { month: 'long' }),
    y: d.balance,
    credit: d.credit,
    debit: d.debit
  })));

  const y = ref();
  const x = ref();

  watchEffect(() => {
    x.value = scaleBand()
      .range([0, props.width])
      .padding(props.barPadding)
      .domain(data.value.map(e => e.x));

    y.value = scaleLinear()
      .range([props.height, 0])
      .domain([0, Math.max(...data.value.map(e => e.y))]);
  });

  const yTicks = computed(() => y.value.ticks(props.tickCount));

  const bars = computed(() => data.value.map(d => ({
    xLabel: d.x,
    x: x.value(d.x),
    y: y.value(d.y),
    width: x.value.bandwidth(),
    height: props.height - y.value(d.y),
    credit: colorCurrency(d.credit, props.currency),
    debit: colorCurrency(d.debit, props.currency),
    balance: formatCurrency(d.y, props.currency, 2, '%s%v', '')
  })));
</script>

<style lang="scss">
  .BarChart {
    font-size: .14rem;
    line-height: .24rem;

    /* Color Placeholder - #766E68 - $color-placeholder */
    color: #766e68;

    .bar:hover {
      opacity: .8;
    }
  }
</style>
