export default function (instance) {
  return {
     getPreset () {
      return instance
        .get('/api/subscriptions/preset')
        .then(response => response.data)
    },

    accept () {
      return instance
        .post('/api/subscriptions/tc-acceptance')
        .then(response => response.data)
    },

    getSummary (page = 'preview') {
      return instance
        .get(`/api/subscriptions/summary?page=${page}`, {
           timeout: 120000
        })
        .then(response => response.data)
    },

    getAlternatives () {
      return instance
        .get('/api/subscriptions/alternatives', {
          timeout: 80000
        })
        .then(response => response.data)
    },

    getComparison (productId) {
      return instance
        .get(`/api/subscriptions/compare/${productId}`, {
          timeout: 80000
        })
        .then(response => response.data)
    },

    update (productId) {
      return instance
        .post(`/api/subscriptions/update/${productId}`, {
          timeout: 60000
        })
        .then(response => response.data)
    },

    getAllPackagesInfo () {
      return instance
        .get('/api/subscriptions/package-prices')
        .then(response => response.data);
    }
  }
}
