<template>
  <VueFinalModal
    class="AppModal"
    v-model="modelValue"
    :name="name"
    :esc-to-close="false"
    :click-to-close="false"
    :overlayTransition="'vfm-fade'"
    :contentTransition="'vfm-fade'"
    :background="closeFromOutside ? 'non-interactive' : 'interactive'"
    @closed="closed"
    @click-outside="clickOutside"

  >
    <div class="vfm__content_header">
      <h6 class="vfm__content_title">
        <span v-if="topIcon">
          <app-icon class="top-icon" :name="topIcon" />
        </span>
        <span>{{ title }}</span>
      </h6>
      <a v-if="closeFromWithin" class="vfm--modal_btn-close" @click.prevent="close">
        <app-icon name="close" />
      </a>
    </div>
    <div class="vfm__content_body">
      <slot></slot>
    </div>
    <div class="vfm-modal_footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </VueFinalModal>
</template>

<script setup>
  import { toRefs } from 'vue';
  import { VueFinalModal } from 'vue-final-modal';

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    closeFromWithin: {
      type: Boolean,
      required: false,
      default: true
    },
    closeFromOutside: {
      type: Boolean,
      required: false,
      default: true
    },
    closed: {
      type: Function,
      required: false
    },
    clickOutside: {
      type: Function,
      required: false
    },
    topIcon:{
      type: String,
      required: false
    } 
  });

  const { modelValue } = toRefs(props);

  const emit = defineEmits(['update:modelValue']);

  const close = () => {
    emit('update:modelValue', false);
  };
</script>

<style lang="scss">
  .AppModal {
    &.vfm {
      z-index: $zindex-modal!important;
      display: flex;
      align-items: center;
      justify-content: center;

      .vfm--overlay {
        background: rgba(51, 51, 51, .4);
      }

      .vfm__content {
        box-shadow: 0 .02rem .4rem rgba(51, 51, 51, .2);
        width: 600px;
        margin: .1rem!important;
        left: 0 !important;

        @include media (tablet) {
          border-radius: .08rem;
        }

        &_header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: $primary;
          color: $white;
          padding: .15rem .2rem;
          border-top-left-radius: .08rem;
          border-top-right-radius: .08rem;

          @include media (tablet) {
            padding: .15rem .3rem;
          }
        }

        &_title {
          font-weight: 300;
          font-size: .16rem;
          line-height: .18rem;

          span {
            svg {
              width: 24px;
              height: 24px;
              color: #fff;
              margin:0 10px 0 0;
            } 
          } 

          @include media (tablet) {
            font-size: .24rem;
            line-height: .3rem;
          }
        }

        &_body {
          padding: .2rem;
          position: relative;
          background-color: #fff;
          border-bottom-left-radius: .08rem;
          border-bottom-right-radius: .08rem;

          @include media (tablet) {
            padding: .3rem;
          }
        }

        .icon-close {
          width: .1rem;
          height: .1rem;
        }
      }
    }
  }
</style>
